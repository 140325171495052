
import {lerp} from 'canvas-sketch-util/math'
import random from 'canvas-sketch-util/random'
import niceColors from 'nice-color-palettes'

const colorPalette = random.shuffle(random.pick(niceColors)).slice(0, random.rangeFloor(3, 6))

type drawParams = {ctx: CanvasRenderingContext2D, width: number, height: number}

function drawPointGrid({ctx, width, height}: drawParams) {
    ctx.clearRect(0, 0, width, height)
    const grid = createGrid(random.rangeFloor(10, 100)).filter(() => Math.random() > 0.5)
    const margin = 50
    grid.forEach(([u, v]) => {
        ctx.strokeStyle = random.pick(colorPalette)
        ctx.lineWidth = random.rangeFloor(1, 10)
        const x = lerp(margin, width - margin, u)
        const y = lerp(margin, height - margin, v)
        const radius = Math.abs(random.gaussian()) * 0.01 * width;
        ctx.beginPath()
        ctx.arc(x, y, radius, 0, Math.PI * 2)
        ctx.stroke()
    })
}

function drawNoiseBubbles({ctx, width, height}: drawParams) {
    ctx.clearRect(0, 0, width, height)
    const grid = createGrid(random.rangeFloor(10, 30))
    const margin = 100
    grid.forEach(([u, v]) => {
        ctx.fillStyle = random.pick(colorPalette)
        ctx.lineWidth = 3
        const x = lerp(margin, width - margin, u)
        const y = lerp(margin, height - margin, v)
        const frequency = random.rangeFloor(2, 10)
        const radius = Math.abs(random.noise2D(x * frequency, y * frequency)) * 0.05 * width;
        ctx.beginPath()
        ctx.arc(x, y, radius, 0, lerp(0, Math.PI * 2, random.value()), false)
        ctx.fill()
    })
}

function drawNoisyWhy({ctx, width, height}: drawParams) {
    ctx.clearRect(0, 0, width, height)
    const grid = createGrid(random.rangeFloor(30, 100))
    const margin = 100
    grid.forEach(([u, v]) => {
        ctx.save()
        ctx.fillStyle = random.pick(colorPalette)
        const x = lerp(margin, width - margin, u)
        const y = lerp(margin, height - margin, v)
        const frequency = random.rangeFloor(2, 50)
        const noise =  Math.abs(random.noise2D(x * frequency, y * frequency))
        const radius = noise * 0.1 * width;
        ctx.font = `${radius}px "Helvitica"`
        ctx.translate(x, y)
        ctx.rotate(noise)
        ctx.fillText('!', 0, 0)
        ctx.fill()
        ctx.restore()
    })
}

function createGrid(count: number = 5) {
    const points = []
    for (let x = 0; x < count; x++) {
        for (let y = 0; y < count; y++) {
            const u = x / (count - 1)
            const v = y / (count - 1)
            points.push([u, v])
        }
    }
    return points
}


export {drawPointGrid, drawNoiseBubbles, drawNoisyWhy}