import React, { useEffect, useRef } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import {Box} from '@chakra-ui/react'
import { sample } from 'lodash'
import * as artGenerators from "./artGenerators"

// @ts-expect-error
const randomArt = artGenerators[sample(Object.keys(artGenerators))]

function GenerativeArtDemo({ drawArt = randomArt }) {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const { width = 512, height = 448, ref } = useResizeDetector()

    useEffect(() => {
        const ctx = canvasRef?.current?.getContext("2d")
        if (ctx) drawArt({ ctx, width, height })
    }, [canvasRef.current, width, height])

    return (
        <Box
            ref={ref}
            width="full"
            maxWidth="calc(100vw - 2rem)"
            height={["sm", "md"]}
            borderRadius="md"
            overflow="hidden"
        >
            <canvas ref={canvasRef} width={width || "100%"} height={height} />
        </Box>
    )
}

export {GenerativeArtDemo}